import Amplify from '@aws-amplify/api';

// *************************** Import CSS *************************** //

import "./fs2024-feature.css";

// *************************** Import Images *************************** //

// ** Story Images ** //

import "/src/assets/fall-sessions-2024/fs2024-javascript-confetti.png";
import "/src/assets/fall-sessions-2024/fs2024-virtual-students.png";
import "/src/assets/fall-sessions-2024/fs2024-financial-literacy.png";
import "/src/assets/fall-sessions-2024/fs2024-federal-reserve.png";
import "/src/assets/fall-sessions-2024/fs2024-compound-interest.png";

// ** Sponsors ** //

import "/src/assets/sponsor-logos/spwfg-logo.png";
import "/src/assets/sponsor-logos/live-oak-public-libraries.png";

// *************************************************** Insert Fall Sessions 2024 Feature **************************************************** //

class FS2024Feature extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

<!-- *********************************** BEGIN: FALL 2024 SATURDAY SESSIONS *************************************** -->

   <!-- ************** BEGIN: Title of Section ************** -->

    <div class="container">
  
      <div class="col-lg-12 text-center mb-4">

        <span class="col-3 col-md-2 col-lg-1 badge badge-dark-purple text-white mt-4">
          <img src="white-brackets.png" height="30px" alt="html brackets">
        </span>

        <div class="row col-12 d-flex justify-content-center text-center mx-auto mt-2">

          <p class="col-12 col-md-6 col-lg-5 section-title-size serif-heading bold text-center text-md-end p-0 m-0 text-white">
              Fall 2024
          </p>

          <p class="col-12 col-md-6 section-title-size serif-heading bold text-center text-md-start p-0 ps-md-2 m-0 text-white">
              Saturday Sessions
          </p>

        </div>

        <p class="section-title-size serif-heading bold mx-auto text-center mt-0 pt-0 pb-0 mb-2 text-white">
            Celebrating Coding, Connecting, &#38; Compound Interest
        </p>

      </div>

  </div>

  <!-- ************** END: Title of Section ************** -->


  <!-- ************** BEGIN: Confetti Button ************** -->

  <div class="container-fluid">

    <div class="row col-12 col-lg-8 d-flex justify-content-center text-center mx-auto">

    <section class="confetti-button-section">
    
      <button id="confetti-button" class="btn bold" type="button">
        CLICK TO CELEBRATE <span class="button-emoji">&#127881;</span></button>
    
    </section>

    </div>

  </div>

  <!-- ********** END: Confetti Button ********** -->


  <!-- ************* BEGIN: Fall 2024 Saturday Sessions Description *********** -->

  <div class="container">

    <div class="col-12 col-lg-9 mt-2 pt-3 mt-xl-0 text-center mx-auto">

      <p class="text-size-body pb-2 ps-2 ps-md-0 text-off-white text-start bold">
        Girls Code Savannah once again partnered with 
        <a class="text-gold serif-heading text-size-h3 bold" href="https://liveoakpl.org/home" target="_blank">Live Oak Public Libraries</a>
        and held our FREE Fall "Saturday Sessions" at the Bull Street &#38; SW Chatham Library locations. 
        The sessions took place from 11:00 AM &#8208; 1:30 PM on four Saturdays throughout October &#38; November.
      </p>

      <p class="text-size-body pb-0 ps-2 ps-md-0 text-off-white text-start bold">
        Nearly 50 students attended our Fall 2024 Saturday Sessions. Our beginner students used HTML & CSS coding languages to 
        <a class="text-gold serif-heading text-size-h3 bold" href="student-websites.html">build their own websites</a>
        from a blank screen. Our returning students continued evolving their 
        <a class="text-gold serif-heading text-size-h3 bold" href="student-websites.html">already-created websites</a> by learning how to code 
        and customize the JavaScript-enabled confetti seen by clicking the celebration button above.
      </p>

      <div class="row col-12 col-md-10 d-flex justify-content-center mx-auto">

        <div class="col-12 col-md-6 col-lg-6 pt-2">
            <img class="fs2024-photo" src="fs2024-javascript-confetti.png" height="175px" alt="photo of students coding">
        </div>

        <div class="col-12 col-md-6 col-lg-6 pt-2">
            <img class="fs2024-photo" src="fs2024-virtual-students.png" height="175px" alt="photo of zoom call">
        </div>

      </div>

      <p class="text-size-body pt-4 ps-2 ps-md-0 text-off-white text-start bold">
        Our Fall 2024 Saturday Sessions also kicked off a couple of exciting Girls Code Savannah "firsts". After a few of our long-time students relocated out of state, we opened our Returning Students classes to include virtual students. We will continue to offer this option to Returning Students going forward. The joy of keeping our growing community connected (and the fun of silly moustaches) sealed the deal! 
      </p>

      <p class="text-size-body pt-2 ps-2 ps-md-0 text-off-white text-start bold">
        Fall 2024 Saturday Sessions marked the beginning of incorporating financial literacy into our overall curriculum. This has always been part of our Executive Director's vision for the program. As an applied economist, she simply loves teaching it. As a software developer, she feels it is very important to equip students with the knowledge of how to responsibly care for and grow the money that often accompanies a lucrative career in STEM fields.
      </p>


      <div class="row col-12 col-md-10 d-flex justify-content-center mx-auto">
        
        <div class="col-12 col-md-6 col-lg-6 pt-2">
          <img class="fs2024-photo" src="fs2024-financial-literacy.png" alt="financial literacy banner">
        </div>

      </div>


      <div class="row col-12 col-md-10 d-flex justify-content-center mx-auto">
        
        <div class="col-12 col-md-6 col-lg-6 pt-3">
          <img class="fs2024-photo" src="fs2024-federal-reserve.png" width="550px" alt="photo of students coding">
        </div>

        <div class="col-12 col-md-6 col-lg-6 pt-3">
          <img class="fs2024-photo" src="fs2024-compound-interest.png" width="550px" alt="photo of students coding">
        </div>

      </div>

      <p class="text-size-body pt-4 ps-2 ps-md-0 text-off-white text-start bold">
        Along with continuing their coding education, over the 4 Fall Saturday Sessions students also learned the about (1) the history of and meaningful graphic symbolism incorporated into the dollar bill, (2) the Federal Reserve System and the role it plays in storing and distributing money, (3) the history and creation of coins, and (4) the magic of compound interest. To say that our students were exponentially intrigued is an understatement. 
        <span class="emoji">&#128521;</span> And we're just getting started.
      </p>

      <p class="text-size-body pt-2 ps-2 ps-md-0 text-off-white text-start bold">
        As always, we are incredibly thankful to our generous sponsors and partners who support our efforts and helped to make our Fall 2024 Saturday Sessions a great success!         

        Please 
        <a class="text-gold serif-heading text-size-h3 bold" href="contact.html">contact us</a>
        or 
        <a class="text-gold serif-heading text-size-h3 bold" href="donate.html">donate</a> if you would also like to show your support.
      </p>

    </div>

    <!-- *************** END: Spring 2024 Saturday Sessions Description ************ -->


    <!-- *************** BEGIN: Spring 2024 Sponsors ************ -->

      <div class="col-12 text-center mx-auto">     

      <div class="container">

        <div class="d-flex justify-content-center mt-3">
          <p class="serif-heading bold mx-auto text-center m-2 text-size-secondary-heading text-white">
            &nbsp;Thank You to Our Fall 2024 Sponsors &#38; Partners!&nbsp;
          </p>
        </div>

      <!-- BEGIN: Sponsor Logo Section -->

        <div class="row col-12 justify-content-center pt-1 pb-3">

      <!-- BEGIN: Live Oak & SPWFG -->

          <div class="row col-12 col-md-10 col-lg-6">

          <div class="col-12 col-md-8 col-lg-8 spwfg-img pb-2 text-center mx-auto">
            <a href="https://www.grapevine.org/giving-circle/JXjhk2l/Savannah-Professional-Women-for-Good" target="_blank">
              <img class="sponsor-logo-img" src="spwfg-logo.png" height="70px" alt="city of savannah logo">
            </a>
          </div>

          <div class="col-12 col-md-8 col-lg-8 live-oak-img bg-white">
              <a href="https://liveoakpl.org/home" target="_blank">
                <img class="sponsor-logo-img" src="live-oak-public-libraries.png" height="70" alt="live oak libraries logo">
              </a>
          </div>

          </div>

    <!-- END: Live Oak & SPWFG -->

        </div>

    <!-- END: Sponsor Logo Section -->

      </div>

  <!-- *************** END: Spring 2024 Sponsors ************ -->

     </div>

<!-- *********************************** END: FALL 2024 SATURDAY SESSIONS ***************************************** -->

    `
  }
}


// *********************** Define 'extends HTMLElements' Above *********************** //

customElements.define('fs2024-feature', FS2024Feature);


// ************************* Confetti ************************* //

var confettiButton = document.getElementById('confetti-button');

const jsConfetti = new JSConfetti();

confettiButton.addEventListener('click', () => {
  jsConfetti.addConfetti({
    confettiColors: ['#B650B2', '#5E46BF', '#39AFB9', '#F4BF4C', '#72B4DF', '#97517B'],
  })
})

let star = String.fromCodePoint(0X1F31F);
let moneybag = String.fromCodePoint(0X1F4B0);
let computer = String.fromCodePoint(0X1F4BB);
let dollarbill = String.fromCodePoint(0X1F4B5);
let face = String.fromCodePoint(0X1F978);
let partyhat = String.fromCodePoint(0X1F389);

confettiButton.addEventListener('click', () => {
  jsConfetti.addConfetti({
      emojis: [computer , moneybag, dollarbill, star, face, partyhat],
  })
})

    
  