import Amplify from '@aws-amplify/api';

// *************************** Import CSS *************************** //

import "./winter-camp-banner.css";

// *************************** Import Images *************************** //

// ** SNOWFLAKES ** //
import "/src/assets/icons/snowflakes-white.png";

// *************************************************** Insert Winter Camp Banner **************************************************** //

class WCBanner extends HTMLElement {
  connectedCallback() {
    this.innerHTML = `

	<!-- *********************************** BEGIN: BANNER CODE ************************************** --> 

 	<div class="container-fluid">


	<!-- BEGIN: Smaller-Devices Announcement Banner Row -->

      <div class="banner-smaller-devices text-center">

	        <p class="col-12 banner-text text-purple serif-heading text-size-h2 bold pt-2 pb-0 mb-0">
	        
	          <img class="snowflakes-image" src="snowflakes-white.png" width="40" height="40"> 
	          
            &nbsp; Winter Camp 2024 &nbsp;
	        
	          <img class="snowflakes-image" src="snowflakes-white.png" width="40" height="40">

          </p>


          <p class="col-12 banner-text text-purple serif-heading text-size-h2 bold pt-0 pb-0 mb-0">
	        
	          Jan 2nd - 4th

          </p>

	        
          <p class="col-12 banner-text text-purple serif-heading text-size-h2 bold pt-2 pb-0 mb-0">

            &#10094; Special Project &#10095;

          </p>


          <p class="col-12 banner-text text-purple serif-heading text-size-h2 bold pt-2 pb-0 mb-0">
	      
            Coding for the Community!

          </p>

        
          <a href="camps-classes.html"><button type="button" class="banner-register-button btn text-white btn-sm mt-2 mb-2">LEARN MORE</button></a>

      </div>

    <!-- END: Smaller-Devices Announcement Banner Row -->



    <!-- BEGIN: Larger-Devices Announcement Banner Row -->

      <div class="banner-large-devices">

        <div class="col-12 text-center pt-2">

          <p class="banner-text serif-heading text-purple text-size-h2 bold pt-2 pb-0">
            
            <img class="snowflakes-image" src="snowflakes-white.png"> &nbsp;
                
            Winter Camp 2024 &nbsp;<span class="text-hot-pink">|</span> &nbsp;
                
            Jan 2nd - 4th &nbsp; <span class="text-hot-pink">|</span> &nbsp;
                
            &#10094; Special Project &#10095; &nbsp; Coding for the Community! &nbsp;
            
            <img class="snowflakes-image" src="snowflakes-white.png"> &nbsp;
                
            <a href="camps-classes.html">
              <button type="button" class="banner-register-button btn text-white btn-sm">LEARN MORE</button> &nbsp;
            </a>
                
            <img class="snowflakes-image" src="snowflakes-white.png">
              
          </p>
            
        </div>

      </div>

    <!-- END: Larger-Devices Announcement Banner Row -->



   </div>


<!-- *********************************** END: BANNER CODE ************************************** --> 

    `
  }
}


// *********************** Define 'extends HTMLElements' Above *********************** //

customElements.define('winter-camp-banner', WCBanner);